/**
 * Created by Daniel Hinchev on 15/12/2016
 */

define(['accordion', 'jquery'], function(accordion, $) {
  

  var _faqsMenu = {

    _init: function() {
      var faqs = document.querySelectorAll('.faqs');
      if (faqs.length) {
        for (var i = 0, length = faqs.length; i < length; i++) {
          accordion.setClasses(faqs[i], 'media-block', 'media-block', 'faqs-open', 'faqs-close');
        }
      }

      //check you are on the right page by looking for these classes
      if ($('.section-faqs.parent-faqs, .section-faqs.parent-about-exante').length > 0) {
        //create a div in which to populate the faq navigation menu
        $('.widget-block.faqs').not(':first').hide();
        var faqMenuDiv = '<div class="faqManu"><div class="faq-widget-header"><h2>Select a topic</h2></div>';
        faqMenuDiv += '<div class="faq-widget-content"><ul></ul></div></div>';
        $('.middle-rail.column-span12').append(faqMenuDiv);
        var headers = $('.columns-set-faqs .widget-header h2');
        var listContent = '';
        //assign to each menu option a data* and to each corresponding headline a class with the same name
        for (var x = 0; x < headers.length; x++) {
          var uniqueID = 'faq_cont_div_' + x;
          listContent += '<li data-div-id=\'' + uniqueID + '\'>' + $(headers[x]).text() + '</li>';
          $(headers[x]).parents('.widget-block.faqs').addClass(uniqueID);
        }
        $('.faq-widget-content ul').html(listContent);
      }
      $('.faq-widget-content ul li').click(_faqsMenu.clickMenuOption);
    },
    //trigger hide and show tabs
    clickMenuOption: function(evt) {
      $('.widget-block.faqs').hide();
      var divToShow = $(evt.target).attr('data-div-id');
      $('.widget-block.faqs.' + divToShow).show();

    }
  };

  _faqsMenu._init();
  return _faqsMenu;
});
